const checkIntersecaoLayer = (obj) => {
    const nIntersecoes = obj.features.length;
    const alerts = [];

    let OINTERSECAONUMERO_ok = true;
    let OINTERSECAOSITUACAO_ok = true;
    let OCONTAGEM_ok = true;
    let OENDLOCALP_ok = true;
    let OENDLOCALS_ok = true;
    let OENDLOCALT_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nIntersecoes - 1; i++) {
        const int = obj.features;
        if (
            !int[i].properties.hasOwnProperty("OINTERSECAONUMERO") &&
            OINTERSECAONUMERO_ok
        ) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OINTERSECAONUMERO'.`
            );
            OINTERSECAONUMERO_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("OINTERSECAOSITUACAO") &&
            OINTERSECAOSITUACAO_ok
        ) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OINTERSECAOSITUACAO'.`
            );
            OINTERSECAOSITUACAO_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALP") && OENDLOCALP_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALP'.`
            );
            OENDLOCALP_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALS") && OENDLOCALS_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALS'.`
            );
            OENDLOCALS_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALT") && OENDLOCALT_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'OENDLOCALT'."
            );
            OENDLOCALT_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUNOME'."
            );
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OCONTAGEM") && OCONTAGEM_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'OCONTAGEM'."
            );
            OCONTAGEM_ok = false;
        }
    }
    return {
        items: nIntersecoes,
        alerts: alerts,
    };
};

const checkContLayer = (obj) => {
    const nContagens = obj.features.length;
    const alerts = [];

    let OCONTAGEM_ok = true;
    let OCONTAGEMOD_ok = true;
    let OINTERSECAONUMERO_ok = true;
    let OENDLOCALP_ok = true;
    let OENDLOCALS_ok = true;
    let OENDLOCALT_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nContagens - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("OCONTAGEM") && OCONTAGEM_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OCONTAGEM'.`
            );
            OCONTAGEM_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("OCONTAGEMOD") &&
            OCONTAGEMOD_ok
        ) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OCONTAGEMOD'.`
            );
            OCONTAGEMOD_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("OINTERSECAONUMERO") &&
            OINTERSECAONUMERO_ok
        ) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OINTERSECAONUMERO'.`
            );
            OINTERSECAONUMERO_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALP") && OENDLOCALP_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALP'.`
            );
            OENDLOCALP_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALS") && OENDLOCALS_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALS'.`
            );
            OENDLOCALS_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALT") && OENDLOCALT_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'OENDLOCALT'."
            );
            OENDLOCALT_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUNOME'."
            );
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
    }
    return {
        items: nContagens,
        alerts: alerts,
    };
};

const checkProjUrbLayer = (obj) => {
    const nProjUrb = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;

    for (let i = 0; i <= nProjUrb - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
    }
    return {
        items: nProjUrb,
        alerts: alerts,
    };
};

const checkSimLayer = (obj) => {
    const nSimulacoes = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;

    for (let i = 0; i <= nSimulacoes - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
    }
    return {
        items: nSimulacoes,
        alerts: alerts,
    };
};

const checkRotaDesLayer = (obj) => {
    const nRotas = obj.features.length;
    const alerts = [];

    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nRotas - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais rotas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais rotas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais rotas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais rotas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais rotas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
    }
    return {
        items: nRotas,
        alerts: alerts,
    };
};

const checkCoordLayer = (obj) => {
    const nCoordenacao = obj.features.length;
    const alerts = [];

    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let dashArray_ok = true;

    for (let i = 0; i <= nCoordenacao - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais rotas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais rotas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais rotas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais rotas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais rotas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("dashArray") && dashArray_ok) {
            alerts.push(
                "Uma ou mais rotas não possuem o atributo 'dashArray'."
            );
            dashArray_ok = false;
        }
    }
    return {
        items: `${nCoordenacao} rotas`,
        alerts: alerts,
    };
};

const checkCorredorLayer = (obj) => {
    const nCorredores = obj.features.length;
    const alerts = [];

    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nCorredores - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(
                `Um ou mais corredores não possuem o atributo 'stroke'.`
            );
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Um ou mais corredores não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Um ou mais corredores não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push(
                "Um ou mais corredores não possuem o atributo 'IGUNOME'."
            );
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Um ou mais corredores não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
    }
    return {
        items: nCorredores,
        alerts: alerts,
    };
};

const checkLinhasBusLayer = (obj) => {
    const nLinhasBus = obj.features.length;
    const alerts = [];

    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let linha_ok = true;
    let sentido_ok = true;
    let name_ok = true;
    let colorIndex_ok = true;

    for (let i = 0; i <= nLinhasBus - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais linhas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais linhas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais linhas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais linhas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais linhas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("linha") && linha_ok) {
            alerts.push(`Uma ou mais linhas não possuem o atributo 'linha'.`);
            linha_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("sentido") && sentido_ok) {
            alerts.push("Uma ou mais linhas não possuem o atributo 'sentido'.");
            sentido_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("name") && name_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'name'."
            );
            name_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("colorIndex") && colorIndex_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'colorIndex'."
            );
            colorIndex_ok = false;
        }
    }
    return {
        items: nLinhasBus,
        alerts: alerts,
    };
};

const checkProjInterLayer = (obj) => {
    const nAreaInter = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;
    let dashArray_ok = true;

    for (let i = 0; i <= nAreaInter - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("dashArray") && dashArray_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'dashArray'."
            );
            dashArray_ok = false;
        }
    }
    return {
        items: nAreaInter,
        alerts: alerts,
    };
};

const checkRedeSemLayer = (obj) => {
    const nRedeSem = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;
    let dashArray_ok = true;

    for (let i = 0; i <= nRedeSem - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("dashArray") && dashArray_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'dashArray'."
            );
            dashArray_ok = false;
        }
    }
    return {
        items: nRedeSem,
        alerts: alerts,
    };
};

const checkSubRedeSemLayer = (obj) => {
    const nRedeSemSub = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;
    let dashArray_ok = true;

    for (let i = 0; i <= nRedeSemSub - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("dashArray") && dashArray_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'dashArray'."
            );
            dashArray_ok = false;
        }
    }
    return {
        items: nRedeSemSub,
        alerts: alerts,
    };
};

const checkZoneamentoLayer = (obj) => {
    const nZoneamento = obj.features.length;
    const alerts = [];

    let Zona_ok = true;

    for (let i = 0; i <= nZoneamento - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("Zona") && Zona_ok) {
            alerts.push(`Uma ou mais zonas não possuem o atributo 'Zona'.`);
            Zona_ok = false;
        }
    }
    return {
        items: `${nZoneamento} zonas`,
        alerts: alerts,
    };
};

const checkInfoProjLayer = (obj) => {
    const alerts = [];

    if (!obj.hasOwnProperty("projInfo")) {
        alerts.push(`O json não possui o atributo 'projInfo'.`);
    }

    return {
        items: "OK",
        alerts: alerts,
    };
};

const checkViabilidadeSemaforicaLayer = (obj) => {
    const nAvaliacao = obj.features.length;
    const alerts = [];

    let OINTERSECAONUMERO_ok = true;
    let OENDLOCALP_ok = true;
    let OENDLOCALS_ok = true;
    let OENDLOCALT_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nAvaliacao - 1; i++) {
        const int = obj.features;
        if (
            !int[i].properties.hasOwnProperty("OINTERSECAONUMERO") &&
            OINTERSECAONUMERO_ok
        ) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OINTERSECAONUMERO'.`
            );
            OINTERSECAONUMERO_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALP") && OENDLOCALP_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALP'.`
            );
            OENDLOCALP_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALS") && OENDLOCALS_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALS'.`
            );
            OENDLOCALS_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALT") && OENDLOCALT_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'OENDLOCALT'."
            );
            OENDLOCALT_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUNOME'."
            );
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
    }
    return {
        items: nAvaliacao,
        alerts: alerts,
    };
};

const checkTopografiaLayer = (obj) => {
    const nAvaliacao = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nAvaliacao - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUNOME'."
            );
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
    }
    return {
        items: nAvaliacao,
        alerts: alerts,
    };
};

const checkPontosOnibusLayer = (obj) => {
    const nAvaliacao = obj.features.length;
    const alerts = [];

    let ONUMERO_ok = true;
    let OID_ok = true;
    let OENDLOCALP_ok = true;
    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;

    for (let i = 0; i <= nAvaliacao - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("ONUMERO") && ONUMERO_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'ONUMERO'.`
            );
            ONUMERO_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OENDLOCALP") && OENDLOCALP_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OENDLOCALP'.`
            );
            OENDLOCALP_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("OID") && OID_ok) {
            alerts.push(
                `Uma ou mais intersecções não possuem o atributo 'OID'.`
            );
            OID_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUNOME'."
            );
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais intersecções não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
    }
    return {
        items: nAvaliacao,
        alerts: alerts,
    };
};

const checkImplantacaoLayer = (obj) => {
    const nImp = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;

    for (let i = 0; i <= nImp - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
    }
    return {
        items: nImp,
        alerts: alerts,
    };
};

const checkSemConjLayer = (obj) => {
    const nAreaInter = obj.features.length;
    const alerts = [];

    let IGUNOME_ok = true;
    let IGUCAMADA_ok = true;
    let stroke_ok = true;
    let strokeWidth_ok = true;
    let strokeOpacity_ok = true;
    let fill_ok = true;
    let fillOpacity_ok = true;
    let dashArray_ok = true;

    for (let i = 0; i <= nAreaInter - 1; i++) {
        const int = obj.features;
        if (!int[i].properties.hasOwnProperty("stroke") && stroke_ok) {
            alerts.push(`Uma ou mais áreas não possuem o atributo 'stroke'.`);
            stroke_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeWidth") &&
            strokeWidth_ok
        ) {
            alerts.push(
                `Uma ou mais áreas não possuem o atributo 'strokeWidth'.`
            );
            strokeWidth_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("strokeOpacity") &&
            strokeOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'strokeOpacity'."
            );
            strokeOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUNOME") && IGUNOME_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'IGUNOME'.");
            IGUNOME_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("IGUCAMADA") && IGUCAMADA_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'IGUCAMADA'."
            );
            IGUCAMADA_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("fill") && fill_ok) {
            alerts.push("Uma ou mais áreas não possuem o atributo 'fill'.");
            fill_ok = false;
        }
        if (
            !int[i].properties.hasOwnProperty("fillOpacity") &&
            fillOpacity_ok
        ) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'fillOpacity'."
            );
            fillOpacity_ok = false;
        }
        if (!int[i].properties.hasOwnProperty("dashArray") && dashArray_ok) {
            alerts.push(
                "Uma ou mais áreas não possuem o atributo 'dashArray'."
            );
            dashArray_ok = false;
        }
    }
    return {
        items: nAreaInter,
        alerts: alerts,
    };
};

export {
    checkContLayer,
    checkIntersecaoLayer,
    checkCoordLayer,
    checkCorredorLayer,
    checkInfoProjLayer,
    checkLinhasBusLayer,
    checkProjInterLayer,
    checkProjUrbLayer,
    checkRedeSemLayer,
    checkRotaDesLayer,
    checkZoneamentoLayer,
    checkSubRedeSemLayer,
    checkSimLayer,
    checkViabilidadeSemaforicaLayer,
    checkTopografiaLayer,
    checkPontosOnibusLayer,
    checkImplantacaoLayer,
    checkSemConjLayer,
};
