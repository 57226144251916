import React, { useState } from "react";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
    BsFillSignpost2Fill,
    BsCameraVideoFill,
    BsStickiesFill,
    BsFillCursorFill,
    BsGrid3X3,
    BsStoplightsFill,
    BsReceipt,
    BsArrowRepeat,
    BsBezier2,
    BsIntersect,
    BsDistributeHorizontal,
    BsHddNetworkFill,
    BsShareFill,
    BsShare,
    BsSpeedometer,
    BsTriangleHalf,
    BsFileEarmarkSlidesFill,
    BsFileEarmarkPlay,
    BsFillSignpostSplitFill,
    BsGlobe,
    BsTools,
    BsConeStriped,
    BsLink45Deg,
} from "react-icons/bs";
import { FaBusAlt, FaRoute } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";

import IguToolbarB2 from "./_IguToolbarB2";
import IguInterseccaoIcon from "./_IguInterseccaoIcon";
import { toggleLayer } from "../utils/toogleLayerVisibility";
import { useTranslation } from "react-i18next";

const ToolbarButtonsG2 = (props) => {
    const panes = props.panes;
    const { t } = useTranslation("iguToolbarButtonsG2");
    let {
        layerIntSemVisibility,
        layerIntMapVisibility,
        layerViabilidadeSemVisibility,
        layerContVisibility,
        layerContODVisibility,
        layerODVisibility,
        layerProjIntVisibility,
        layerProjSubVisibility,
        layerAreasSimVisibility,
        layerRotasVisibility,
        layerCoordVisibility,
        layerRedeSemVisibility,
        layerSubRedeSemVisibility,
        layerRotasDesempenhoVisibility,
        layerLinhasOnibusVisibility,
        ODControlsVisible,
        layerZoneamentoVisibility,
        layerPontosOnibusVisibility,
        layerTopografiaVisibility,
        layerImp1Visibility,
        layerImp2Visibility,
        layerImp3Visibility,
        layerImp4Visibility,
        layerImp5Visibility,
        layerImp6Visibility,
        layerImp7Visibility,
        layerImp8Visibility,
        layerImp9Visibility,
        layerSemConjVisibility,
    } = props.layerVisibility;

    const {
        intSem,
        intMap,
        viabilidadeSem,
        contVeic,
        contagemOD,
        contOD,
        projInt,
        projSub,
        areasSim,
        rotas,
        coord,
        redeSem,
        subRedeSem,
        rotasDes,
        linhasBus,
        odData,
        pontosOnibus,
        topografia,
        imp1,
        imp2,
        imp3,
        imp4,
        imp5,
        imp6,
        imp7,
        imp8,
        imp9,
        semConj,
    } = props.quantities;

    // Viabilidade Semafórica
    const viabilidadeCategories = ["Viabilidade Semafórica"];
    const [viabilidadeSemLayerIsOn, setViabilidadeSemLayerIsOn] = useState(
        layerViabilidadeSemVisibility
    );
    const viabilidadeIcons = [<BsTriangleHalf />];

    // Interseccoes
    const interseccoesCategories = [
        t("intCat.intSem"),
        t("intCat.intMap"),
        t("intCat.semConj"),
        t("intCat.intAv"),
    ];
    const interseccoesIcons = [
        <IguInterseccaoIcon />,
        <BsStoplightsFill />,
        <BsLink45Deg />,
        <BsTriangleHalf />,
    ];
    const [intSemLayerIsOn, setIntSemLayerIsOn] = useState(
        layerIntSemVisibility
    );
    const [intMapLayerIsOn, setIntMapLayerIsOn] = useState(
        layerIntMapVisibility
    );
    const [semConjLayerIsOn, setSemConjLayerIsOn] = useState(
        layerSemConjVisibility
    );

    // Contagens
    const contagensCategories = [
        t("contCat.contVeic"),
        t("contCat.contOD"),
        t("contCat.contOD"),
    ];
    const contagensIcons = [
        <BsCameraVideoFill />,
        <BsArrowRepeat />,
        <BsArrowRepeat />,
    ];
    const [contLayerIsOn, setContLayerIsOn] = useState(layerContVisibility);
    const [odLayerIsOn, setODLayerIsOn] = useState(layerODVisibility); // projetos antigos (RP, Atibaia...)
    const [contODLayerIsOn, setContODLayerIsOn] = useState(
        layerContODVisibility
    );

    // Projetos
    const projetosCategories = [
        t("projCat.projInt"),
        t("projCat.projSub"),
        t("projCat.top"),
    ];
    const projetosIcons = [<BsBezier2 />, <BsIntersect />, <BsGlobe />];
    const [projIntLayerIsOn, setProjIntLayerIsOn] = useState(
        layerProjIntVisibility
    );
    const [projSubLayerIsOn, setProjSubLayerIsOn] = useState(
        layerProjSubVisibility
    );
    const [topografiaLayerIsOn, setTopografiaLayerIsOn] = useState(
        layerTopografiaVisibility
    );

    // Simulacoes
    const simulacaoCategories = [t("simCat.sim")];
    const simulacaoIcons = [<BsFileEarmarkPlay />];
    const [areasSimLayerIsOn, setareasSimLayerIsOn] = useState(
        layerAreasSimVisibility
    );

    // Rotas
    const rotasCategories = [t("routeCat.route")];
    const rotasIcons = [<BsSpeedometer />];
    const [rotasDesLayerIsOn, setRotasDesLayerIsOn] = useState(
        layerRotasDesempenhoVisibility
    );

    // Redes
    const coordCategories = [
        t("netCat.bandCoord"),
        t("netCat.redeSem"),
        t("netCat.subRedeSem"),
    ];
    const coordIcons = [<BsHddNetworkFill />, <BsShareFill />, <BsShare />];
    const [coordLayerIsOn, setCoordLayerIsOn] = useState(layerCoordVisibility);
    const [redeSemLayerIsOn, setRedeSemLayerIsOn] = useState(
        layerRedeSemVisibility
    );
    const [subRedeSemLayerIsOn, setSubRedeSemLayerIsOn] = useState(
        layerSubRedeSemVisibility
    );

    // Transporte público
    const tpCategories = [
        t("pubTransCat.corBus"),
        t("pubTransCat.linhaBus"),
        t("pubTransCat.matrizOD"),
        t("pubTransCat.pontosBus"),
    ];
    const tpIcons = [
        <BsDistributeHorizontal />,
        <FaRoute />,
        <BiNetworkChart />,
        <BsFillSignpostSplitFill />,
    ];
    const [rotasLayerIsOn, setRotasLayerIsOn] = useState(layerRotasVisibility);
    const [linhasBusLayerIsOn, setLinhasBusLayerIsOn] = useState(
        layerLinhasOnibusVisibility
    );
    const [pontosOnibusLayerIsOn, setPontosOnibusLayerIsOn] = useState(
        layerPontosOnibusVisibility
    );
    const { setBusLinhasLegendVisible, setODControlsVisible } =
        props.b2GroupTools;
    const linhasBusOnClick = () => {
        toggleLayer(panes, "paneBus", setLinhasBusLayerIsOn);
        setBusLinhasLegendVisible((prevState) => !prevState);
    };
    const [zoneamentoLayerIsOn, setZoneamentoLayerIsOn] = useState(
        layerZoneamentoVisibility
    );
    const odOnClick = () => {
        // toggle zonasLabels visibility
        document.querySelectorAll(".zonasLabels").forEach((zonaLabel) => {
            zonaLabel.classList.toggle("zonasLabels-invisible");
        });
        setODControlsVisible((prevState) => !prevState);
        toggleLayer(panes, "paneZoneamento", setZoneamentoLayerIsOn);
    };

    // Implantação
    const impCategories = [
        t("impCat.phase") + " 1",
        t("impCat.phase") + " 2",
        t("impCat.phase") + " 3",
        t("impCat.phase") + " 4",
        t("impCat.phase") + " 5",
        t("impCat.phase") + " 6",
        t("impCat.phase") + " 7",
        t("impCat.phase") + " 8",
        t("impCat.phase") + " 9",
    ];
    const impIcons = [
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
        <BsConeStriped />,
    ];
    const [imp1LayerIsOn, setImp1LayerIsOn] = useState(layerImp1Visibility);
    const [imp2LayerIsOn, setImp2LayerIsOn] = useState(layerImp2Visibility);
    const [imp3LayerIsOn, setImp3LayerIsOn] = useState(layerImp3Visibility);
    const [imp4LayerIsOn, setImp4LayerIsOn] = useState(layerImp4Visibility);
    const [imp5LayerIsOn, setImp5LayerIsOn] = useState(layerImp5Visibility);
    const [imp6LayerIsOn, setImp6LayerIsOn] = useState(layerImp6Visibility);
    const [imp7LayerIsOn, setImp7LayerIsOn] = useState(layerImp7Visibility);
    const [imp8LayerIsOn, setImp8LayerIsOn] = useState(layerImp8Visibility);
    const [imp9LayerIsOn, setImp9LayerIsOn] = useState(layerImp9Visibility);
    const impGroupClick = () => {
        toggleLayer(panes, "paneImp1", setImp1LayerIsOn);
        toggleLayer(panes, "paneImp2", setImp2LayerIsOn);
        toggleLayer(panes, "paneImp3", setImp3LayerIsOn);
        toggleLayer(panes, "paneImp4", setImp4LayerIsOn);
        toggleLayer(panes, "paneImp5", setImp5LayerIsOn);
        toggleLayer(panes, "paneImp6", setImp6LayerIsOn);
        toggleLayer(panes, "paneImp7", setImp7LayerIsOn);
        toggleLayer(panes, "paneImp8", setImp8LayerIsOn);
        toggleLayer(panes, "paneImp9", setImp9LayerIsOn);
    };

    return (
        <ButtonGroup aria-label="b1" className="igu-toolbar-group2">
            {/* interseccoes */}
            <IguToolbarB2
                icon={<BsFillSignpost2Fill />}
                description={t("int")}
                categories={interseccoesCategories}
                categoriesIcons={interseccoesIcons}
                onClick={[
                    () => toggleLayer(panes, "paneIntSem", setIntSemLayerIsOn),
                    () => toggleLayer(panes, "paneIntMap", setIntMapLayerIsOn),
                    () =>
                        toggleLayer(panes, "paneSemConj", setSemConjLayerIsOn),
                ]}
                layerVisibility={[
                    intSemLayerIsOn,
                    intMapLayerIsOn,
                    semConjLayerIsOn,
                ]}
                quantities={[intSem, intMap, semConj]}
            ></IguToolbarB2>

            {/* contagens */}
            <IguToolbarB2
                icon={<BsCameraVideoFill />}
                description={t("cont")}
                categories={contagensCategories}
                categoriesIcons={contagensIcons}
                onClick={[
                    () => toggleLayer(panes, "paneCont", setContLayerIsOn),
                    () =>
                        toggleLayer(
                            panes,
                            "paneContagemOD",
                            setContODLayerIsOn
                        ),
                    () => toggleLayer(panes, "paneContOD", setODLayerIsOn), // projetos antigos (RP, Atibaia...)
                ]}
                layerVisibility={[contLayerIsOn, contODLayerIsOn, odLayerIsOn]}
                quantities={[contVeic, contagemOD, contOD]}
            ></IguToolbarB2>

            {/* projetos */}
            <IguToolbarB2
                icon={<BsStickiesFill />}
                description={t("proj")}
                categories={projetosCategories}
                categoriesIcons={projetosIcons}
                onClick={[
                    () =>
                        toggleLayer(
                            panes,
                            "paneProjInterligacao",
                            setProjIntLayerIsOn
                        ),
                    () =>
                        toggleLayer(
                            panes,
                            "paneProjSubtrecho",
                            setProjSubLayerIsOn
                        ),
                    () =>
                        toggleLayer(
                            panes,
                            "paneTopografia",
                            setTopografiaLayerIsOn
                        ),
                ]}
                layerVisibility={[
                    projIntLayerIsOn,
                    projSubLayerIsOn,
                    topografiaLayerIsOn,
                ]}
                quantities={[projInt, projSub, topografia]}
            ></IguToolbarB2>

            {/* simulações */}
            <IguToolbarB2
                icon={<BsFileEarmarkSlidesFill />}
                description={t("sim")}
                categories={simulacaoCategories}
                categoriesIcons={simulacaoIcons}
                onClick={[
                    () =>
                        toggleLayer(panes, "paneAreaSim", setareasSimLayerIsOn), // projetos antigos (RP, Atibaia...)
                ]}
                layerVisibility={[areasSimLayerIsOn]}
                quantities={[areasSim]}
            ></IguToolbarB2>

            {/* viablidade sem */}
            <IguToolbarB2
                icon={<BsReceipt />}
                description={t("via")}
                categories={viabilidadeCategories}
                categoriesIcons={viabilidadeIcons}
                onClick={[
                    () =>
                        toggleLayer(
                            panes,
                            "paneViabilidadeSem",
                            setViabilidadeSemLayerIsOn
                        ),
                ]}
                layerVisibility={[viabilidadeSemLayerIsOn]}
                quantities={[viabilidadeSem]}
            ></IguToolbarB2>

            {/* rotas */}
            <IguToolbarB2
                icon={<BsFillCursorFill />}
                description={t("routes")}
                categories={rotasCategories}
                categoriesIcons={rotasIcons}
                onClick={[
                    () =>
                        toggleLayer(
                            panes,
                            "paneDesempenho",
                            setRotasDesLayerIsOn
                        ),
                ]}
                layerVisibility={[rotasDesLayerIsOn]}
                quantities={[rotasDes]}
            ></IguToolbarB2>

            {/* redes */}
            <IguToolbarB2
                icon={<BsGrid3X3 />}
                description={t("net")}
                categories={coordCategories}
                categoriesIcons={coordIcons}
                onClick={[
                    () => toggleLayer(panes, "paneCoord", setCoordLayerIsOn),
                    () =>
                        toggleLayer(panes, "paneRedeSem", setRedeSemLayerIsOn),
                    () =>
                        toggleLayer(
                            panes,
                            "paneSubRedeSem",
                            setSubRedeSemLayerIsOn
                        ),
                ]}
                layerVisibility={[
                    coordLayerIsOn,
                    redeSemLayerIsOn,
                    subRedeSemLayerIsOn,
                ]}
                quantities={[coord, redeSem, subRedeSem]}
            ></IguToolbarB2>

            {/* transporte publico */}
            <IguToolbarB2
                icon={<FaBusAlt />}
                description={t("pubTrans")}
                categories={tpCategories}
                categoriesIcons={tpIcons}
                onClick={[
                    () => toggleLayer(panes, "paneRotas", setRotasLayerIsOn),
                    () => linhasBusOnClick(),
                    () => odOnClick(),
                    () =>
                        toggleLayer(
                            panes,
                            "panePontosOnibus",
                            setPontosOnibusLayerIsOn
                        ),
                ]}
                layerVisibility={[
                    rotasLayerIsOn,
                    linhasBusLayerIsOn,
                    ODControlsVisible,
                    pontosOnibusLayerIsOn,
                ]}
                quantities={[
                    rotas,
                    linhasBus,
                    odData ? odData : 0,
                    pontosOnibus,
                ]}
                ehTP={true}
            />

            {/* implantação */}
            <IguToolbarB2
                icon={<BsTools />}
                description={t("imp")}
                categories={impCategories}
                categoriesIcons={impIcons}
                onClick={[
                    () => toggleLayer(panes, "paneImp1", setImp1LayerIsOn),
                    () => toggleLayer(panes, "paneImp2", setImp2LayerIsOn),
                    () => toggleLayer(panes, "paneImp3", setImp3LayerIsOn),
                    () => toggleLayer(panes, "paneImp4", setImp4LayerIsOn),
                    () => toggleLayer(panes, "paneImp5", setImp5LayerIsOn),
                    () => toggleLayer(panes, "paneImp6", setImp6LayerIsOn),
                    () => toggleLayer(panes, "paneImp7", setImp7LayerIsOn),
                    () => toggleLayer(panes, "paneImp8", setImp8LayerIsOn),
                    () => toggleLayer(panes, "paneImp9", setImp9LayerIsOn),
                ]}
                layerVisibility={[
                    imp1LayerIsOn,
                    imp2LayerIsOn,
                    imp3LayerIsOn,
                    imp4LayerIsOn,
                    imp5LayerIsOn,
                    imp6LayerIsOn,
                    imp7LayerIsOn,
                    imp8LayerIsOn,
                    imp9LayerIsOn,
                ]}
                quantities={[
                    imp1,
                    imp2,
                    imp3,
                    imp4,
                    imp5,
                    imp6,
                    imp7,
                    imp8,
                    imp9,
                ]}
                groupClick={impGroupClick}
            ></IguToolbarB2>
        </ButtonGroup>
    );
};

export default ToolbarButtonsG2;
