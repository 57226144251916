import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";

import {
    BsPlusSquareDotted,
    BsFillPencilFill,
    BsExclamationCircle,
    BsCheckCircle,
    BsInfoCircle,
    BsXSquareFill,
    BsFillFileEarmarkTextFill,
} from "react-icons/bs";

import {
    checkContLayer,
    checkIntersecaoLayer,
    checkCoordLayer,
    checkCorredorLayer,
    checkInfoProjLayer,
    checkLinhasBusLayer,
    checkProjInterLayer,
    checkProjUrbLayer,
    checkRedeSemLayer,
    checkRotaDesLayer,
    checkZoneamentoLayer,
    checkSubRedeSemLayer,
    checkSimLayer,
    checkViabilidadeSemaforicaLayer,
    checkTopografiaLayer,
    checkPontosOnibusLayer,
    checkImplantacaoLayer,
    checkSemConjLayer,
} from "../utils/checkJsonLayers";
import ModalAddJsonFile from "./_AddJsonFileInfoModal";

const JsonFileUploader = (props) => {
    const [jsonFilePath, setJsonFilePath] = useState(null);
    const [validatingJson, setValidatindJson] = useState(false);
    const [InvalidJsonFile, setInvalidJsonFile] = useState(false);
    const [jsonContent, setJsonContent] = useState("");

    const [layerAlerts, setLayerAlerts] = useState(null);
    const [layerElementNum, setLayerElementNum] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const inputFile = useRef(null);
    const fileType = props.tipo ? props.tipo : "";
    const setContent = props.setContent;
    const city = props.city;
    const setHasIssues = props.setHasIssues;
    const [existingJsonsData, setExistingJsonData] = useState(
        props.existingJson === "yes" ? true : false
    );

    // check for existing json on load
    useEffect(() => {
        if (existingJsonsData) {
            setContent({ content: "existing content" });
            setJsonContent("existing content");
            setJsonFilePath(`Arquivo ${fileType} existente.`);
        }
    }, [existingJsonsData, fileType, setContent]);

    // read data
    useEffect(() => {
        setLayerAlerts(null);
        let analyzeResults;
        if (jsonContent && jsonContent !== "existing content") {
            analyzeResults = analyzeJsonFile();
        }
        const { items, alerts } = analyzeResults
            ? analyzeResults
            : { items: null, alerts: [] };
        setLayerElementNum(items);
        if (alerts.length > 0) {
            setLayerAlerts(alerts);
        } else {
            setInvalidJsonFile(false);
            setHasIssues(false);
            // validation end
            setValidatindJson(false);
        }
    }, [jsonContent, city, jsonFilePath, analyzeJsonFile, setHasIssues]);

    // validate data
    useEffect(() => {
        if (layerAlerts && !existingJsonsData) {
            setInvalidJsonFile(true);
            setHasIssues(true);
        } else {
            setInvalidJsonFile(false);
            setHasIssues(false);
        }
        // validation end
        setValidatindJson(false);
    }, [layerAlerts, city, existingJsonsData, setHasIssues]);

    const onChangeHandler = (e) => {
        e.preventDefault();
        setLayerAlerts(null);
        setInvalidJsonFile(false);
        setExistingJsonData(false);
        setJsonFilePath(e.target.files[0].name);
        readJsonContent(e);
    };

    const readJsonContent = (e) => {
        setValidatindJson(true);

        // reading content
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            setJsonContent(event.target.result);
            setContent(event.target.result);
        };
        fileReader.readAsText(e.target.files[0]);
    };

    const analyzeJsonFile = useCallback(() => {
        if (existingJsonsData) return { alerts: [] };
        try {
            const obj = JSON.parse(jsonContent);
            const layer = obj.metadata.tipo;
            const jsonCity = obj.metadata.cidade;
            if (jsonCity !== city) {
                return {
                    alerts: [
                        "Cidade do arquivo JSON não coincide com a inserida; favor verificar e arrumar os dados.",
                    ],
                };
            }
            let checkResult;
            switch (true) {
                case fileType == "Interseções":
                    if (layer !== "Intersecoes") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Intersecção.",
                            ],
                        };
                    } else {
                        checkResult = checkIntersecaoLayer(obj);
                    }
                    break;
                case fileType == "Projetos Urbanos":
                    if (layer !== "ProjetosUrbanosImplantados") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Projetos Urbanos.",
                            ],
                        };
                    } else {
                        checkResult = checkProjUrbLayer(obj);
                    }
                    break;
                case fileType == "Rede de Interligação":
                    if (layer !== "RedeInterligacao") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Projeto de Interligação.",
                            ],
                        };
                    } else {
                        checkResult = checkProjInterLayer(obj);
                    }
                    break;
                case fileType == "Rede Semafórica":
                    if (layer !== "RedeSemaforica") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Rede Semafórica.",
                            ],
                        };
                    } else {
                        checkResult = checkRedeSemLayer(obj);
                    }
                    break;
                case fileType == "Sub-Rede Semafórica":
                    if (layer !== "RedeSub") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de subRede Semafórica.",
                            ],
                        };
                    } else {
                        checkResult = checkSubRedeSemLayer(obj);
                    }
                    break;
                case fileType == "Coordenação":
                    if (layer !== "BandasDeCoordenacao") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Coordenação.",
                            ],
                        };
                    } else {
                        checkResult = checkCoordLayer(obj);
                    }
                    break;
                case fileType == "Corredores de Ônibus":
                    if (layer !== "RotasCorredor") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Rotas de Corredor.",
                            ],
                        };
                    } else {
                        checkResult = checkCorredorLayer(obj);
                    }
                    break;
                case fileType == "Contagens":
                    if (layer !== "Contagens") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Contagem.",
                            ],
                        };
                    } else {
                        // console.log("verificando layer de contagem...");
                        // console.log(obj);
                        checkResult = checkContLayer(obj);
                    }
                    break;
                case fileType == "Simulações":
                    if (layer !== "Simulacao") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Simulação.",
                            ],
                        };
                    } else {
                        checkResult = checkSimLayer(obj);
                    }
                    break;
                case fileType == "Rotas de Desempenho":
                    if (layer !== "RotasDesempenho") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Rotas de Desempenho.",
                            ],
                        };
                    } else {
                        checkResult = checkRotaDesLayer(obj);
                    }
                    break;
                case fileType == "Linhas de Ônibus":
                    if (layer !== "LinhasOnibus") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Linhas de Ônibus.",
                            ],
                        };
                    } else {
                        checkResult = checkLinhasBusLayer(obj);
                    }
                    break;
                case fileType == "Zoneamento":
                    if (layer !== "Zoneamento") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Zoneamento.",
                            ],
                        };
                    } else {
                        checkResult = checkZoneamentoLayer(obj);
                    }
                    break;
                case fileType == "Viabilidade Semafórica":
                    if (layer !== "ViabilidadeSemaforica") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Viabilidade Semafórica.",
                            ],
                        };
                    } else {
                        checkResult = checkViabilidadeSemaforicaLayer(obj);
                    }
                    break;
                case fileType == "Informações do Projeto":
                    if (layer !== "projectInfo") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Informações do Projeto.",
                            ],
                        };
                    } else {
                        checkResult = checkInfoProjLayer(obj);
                    }
                    break;
                case fileType == "Topografia":
                    if (layer !== "Topografia") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Topografia.",
                            ],
                        };
                    } else {
                        checkResult = checkTopografiaLayer(obj);
                    }
                    break;
                case fileType == "Pontos de Ônibus":
                    if (layer !== "PontosOnibus") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Pontos de Ônibus.",
                            ],
                        };
                    } else {
                        checkResult = checkPontosOnibusLayer(obj);
                    }
                    break;
                case fileType == "Semáforos Conjugados":
                    if (layer !== "SemaforosConjugados") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Semáforos Conjugados.",
                            ],
                        };
                    } else {
                        checkResult = checkSemConjLayer(obj);
                    }
                    break;
                case fileType.substring(0, 11) == "Implantação":
                    if (layer.substring(0, 11) !== "Implantacao") {
                        checkResult = {
                            alerts: [
                                "Camada incorreta. Certifique-se de que está subindo um arquivo de Implantação.",
                            ],
                        };
                    } else {
                        checkResult = checkImplantacaoLayer(obj);
                    }
                    break;
                default:
                    checkResult = {
                        alerts: [
                            `Camada incorreta. A camada do tipo ${layer} não existe.`,
                        ],
                    };
                    break;
            }
            return checkResult;
        } catch (e) {
            console.log(e);
            if (e instanceof TypeError) {
                return {
                    alerts: [
                        "Propriedade 'metadata' não encontrada; favor verificar e arrumar os dados.",
                    ],
                };
            } else {
                return {
                    alerts: [
                        "Json inválido; favor verificar a estrutura dos dados.",
                    ],
                };
            }
        }
    }, [fileType, existingJsonsData, city, jsonContent]);

    const infoClickHandler = () => {
        setShowInfoModal(true);
    };

    const clearSelectedJson = () => {
        setLayerAlerts(null);
        setJsonContent(null);
        setContent("");
        setJsonFilePath(null);
    };

    return jsonFilePath && jsonContent ? (
        <>
            <ModalAddJsonFile
                show={showInfoModal}
                handleClose={() => setShowInfoModal(false)}
                alerts={layerAlerts}
                qtd={layerElementNum}
                layerType={fileType}
            />
            <div className="preview-json-container">
                <a
                    href="#/"
                    onClick={() => inputFile.current.click()}
                    className="preview-json"
                >
                    <div className="preview-json-overlay">
                        <BsFillPencilFill
                            className="json-edit-icon"
                            size={25}
                        />
                        <BsXSquareFill
                            className="json-delete-icon-ro"
                            size={25}
                        />
                    </div>
                    <div className="json-file-icon-name-container">
                        <div className="uploaded-json-file-icon">
                            <BsFillFileEarmarkTextFill size={45} />
                        </div>
                        <div className="preview-json-file-name">
                            {jsonFilePath}
                        </div>
                    </div>
                    <input
                        type="file"
                        onChange={(e) => onChangeHandler(e)}
                        ref={inputFile}
                        style={{ display: "none" }}
                    />
                </a>
                <a
                    href="#/"
                    onClick={clearSelectedJson}
                    className="json-delete-icon"
                >
                    <BsXSquareFill size={25} />
                </a>
                {!validatingJson && !InvalidJsonFile && (
                    <BsCheckCircle
                        className="json-file-load-status-icon"
                        color="green"
                    />
                )}
                {InvalidJsonFile && (
                    <BsExclamationCircle
                        className="json-file-load-status-icon"
                        color="orange"
                    />
                )}
                {!validatingJson && jsonFilePath && (
                    <a
                        href="#/"
                        className="json-file-loaded-info-icon"
                        onClick={infoClickHandler}
                    >
                        <BsInfoCircle />
                    </a>
                )}
                {validatingJson && (
                    <img
                        className="json-file-load-status-icon"
                        src="/img/loader2.gif"
                        alt="file-loader"
                        width="21"
                        height="21"
                    />
                )}
                <div className="select-json-text">{fileType}</div>
            </div>
        </>
    ) : (
        <>
            <div className="preview-json-container">
                <Button
                    variant="outline-secondary"
                    onClick={() => inputFile.current.click()}
                    className="select-json-button"
                >
                    <BsPlusSquareDotted
                        size={60}
                        className="select-json-plus-icon"
                    />
                </Button>
                <input
                    type="file"
                    onChange={(e) => onChangeHandler(e)}
                    ref={inputFile}
                    style={{ display: "none" }}
                />
                <div className="select-json-text">{fileType}</div>
            </div>
        </>
    );
};

export default JsonFileUploader;
